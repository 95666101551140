import React, { FC, ReactElement, ReactNode, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import './SystemSeriesDialog.scss';
import './Dialog.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IState, MyCalcThunkDispatch } from '../redux/store';
import {
  BaseProfile,
  ExchangeProfile,
  FrameProfile,
  SashProfile,
  SystemSeries,
} from '../redux/profileDataReducer';
import classNames from 'classnames';
import {
  changeCalculationParameter,
  updateProfileData,
  updateSystemSeries,
} from '../redux/parametersActions';
import ParameterValue from './Parameters/ParameterValue';
import {
  setMarkedBaseProfile,
  setMarkedExchangeProfile,
  setMarkedFrameProfile,
  setMarkedSashProfile,
  showDialog,
  updateMarkedSeries,
} from '../redux/uiStateActions';
import { ReactComponent as IconSucess } from '../assets/icons/IconSucess.svg';
import { DialogFE } from './Dialog';
import SearchField from '../elements/SearchField';
import {
  LockingDrive,
  RangeOfApplication,
  TabOptions,
} from '../redux/constants';
import { ResultTable } from './ResultTable';
import { AnyAction } from 'redux';
import { ValueKey } from '../redux/valueKey';

interface ResultTableProps<T> {
  setMarkedEntry: (t: T) => void;
  markedEntry: T | undefined;
  profileCombination?: boolean;
  columnTitles: string[];
  entries: T[];
  toSearchTuple: (t: T) => string[];
  toRow: (t: T) => string[] | [string, ReactNode] | [string, string, ReactNode];
  heading: string;
  placeHolder: string;
}

const SystemSeriesResultTable = <T extends { id: string }>(
  props: ResultTableProps<T>,
): ReactElement => {
  const [searchString, setSearchString] = useState('');
  const [highlightedEntry, setHighlightedEntry] = useState<T | undefined>();

  function searchBySubString(entry: T, searchValues: string[]): boolean {
    const systemSeriesMaterialLowerCases: string[] = props.toSearchTuple(entry);

    return searchValues.every(searchValue =>
      systemSeriesMaterialLowerCases.some(value =>
        value.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    );
  }

  function searchByMultipleSubstrings(): T[] {
    const splittedSearchString = searchString.split(/\s+/);

    return props.entries.filter(systemSeries =>
      searchBySubString(systemSeries, splittedSearchString),
    );
  }

  return (
    <>
      <div
        className={classNames('system-series-dialog__search-bar-bg', {
          'system-series-dialog__search-bar-bg':
            props.profileCombination === true,
        })}
      >
        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          profileCombination={props.profileCombination}
          placeholder={props.placeHolder}
          captureFocus
        />
      </div>
      <div className="system-series-dialog__table-label">
        <FormattedMessage id={props.heading} />
      </div>
      <ResultTable
        highlightedEntry={
          props.profileCombination ? highlightedEntry : props.markedEntry
        }
        rowClickCallback={
          props.profileCombination ? setHighlightedEntry : props.setMarkedEntry
        }
        addCallback={props.profileCombination ? props.setMarkedEntry : null}
        markedEntry={props.markedEntry}
        columnTitles={props.columnTitles}
        toRow={props.toRow}
        entries={searchByMultipleSubstrings()}
      />
    </>
  );
};

interface NavigationBarItemProps {
  label: string;
  content: string | undefined;
  selected: boolean;
  removalCallback: () => void;
}

const NavigationBarItem: FC<React.PropsWithChildren<NavigationBarItemProps>> = (
  props: NavigationBarItemProps,
) => {
  return (
    <div
      className={classNames('system-series-dialog__navigation-bar-item', {
        'system-series-dialog__navigation-bar-item--empty': !props.content,
        'system-series-dialog__navigation-bar-item--selected': props.selected,
        'system-series-dialog__navigation-bar-item--filled': props.content,
      })}
    >
      {props.content === undefined ? (
        <div>
          <FormattedMessage id={props.label} />
        </div>
      ) : null}

      {props.content ? (
        <>
          <div
            className="system-series-dialog__navigation-bar-item-close-button"
            onClick={props.removalCallback}
          />
          <div className="system-series-dialog__navigation-bar-item-content">
            {props.content}
          </div>
        </>
      ) : null}
    </div>
  );
};

interface NavigationBarProps {
  markedSeries: SystemSeries | undefined;
  markedSashProfile: SashProfile | undefined;
  markedFrameProfile: FrameProfile | undefined;
  markedExchangeProfile: ExchangeProfile | undefined;
  markedBaseProfile: BaseProfile | undefined;
  resetMarkedSystem: () => void;
  resetMarkedSashProfile: () => void;
  resetMarkedFrameProfile: () => void;
  resetMarkedExchangeProfile: () => void;
  resetMarkedBaseProfile: () => void;
}

const NavigationBar: FC<React.PropsWithChildren<NavigationBarProps>> = (
  props: NavigationBarProps,
) => {
  return (
    <div className="system-series-dialog__navigation-bar">
      <NavigationBarItem
        label="SYSTEM_PLUS_SERIES"
        content={
          props.markedSeries &&
          `${props.markedSeries.series}, ${props.markedSeries.system}`
        }
        selected={!props.markedSeries}
        removalCallback={props.resetMarkedSystem}
      />
      <NavigationBarItem
        label="fenster_edit_fluegelprofil"
        content={props.markedSashProfile?.itemNumber}
        selected={!!(props.markedSeries && !props.markedSashProfile)}
        removalCallback={props.resetMarkedSashProfile}
      />
      <NavigationBarItem
        label="fenster_edit_blendrahmen"
        content={props.markedFrameProfile?.itemNumber}
        selected={
          !!(
            props.markedSeries &&
            props.markedSashProfile &&
            !props.markedFrameProfile
          )
        }
        removalCallback={props.resetMarkedFrameProfile}
      />
      {props.markedFrameProfile?.exchangeProfileRequired && (
        <NavigationBarItem
          label="fenster_edit_wechselprofil"
          content={props.markedExchangeProfile?.itemNumber}
          selected={
            !!(
              props.markedSeries &&
              props.markedSashProfile &&
              props.markedFrameProfile &&
              !props.markedExchangeProfile
            )
          }
          removalCallback={props.resetMarkedExchangeProfile}
        />
      )}
      {props.markedFrameProfile?.baseProfilePossible && (
        <NavigationBarItem
          label="fenster_edit_grundprofil"
          content={props.markedBaseProfile?.itemNumber}
          selected={
            !!(
              props.markedSeries &&
              props.markedSashProfile &&
              props.markedFrameProfile &&
              !props.markedBaseProfile &&
              ((props.markedFrameProfile.exchangeProfileRequired &&
                props.markedExchangeProfile) ||
                !props.markedFrameProfile.exchangeProfileRequired)
            )
          }
          removalCallback={props.resetMarkedBaseProfile}
        />
      )}
    </div>
  );
};

interface SystemSeriesDialogProps {
  profileCombination: boolean;
}

const SystemSeriesDialog: FC<
  React.PropsWithChildren<SystemSeriesDialogProps>
> = (props: SystemSeriesDialogProps) => {
  const isFacade = useSelector<IState, boolean>(
    state =>
      state.parameters.rangeOfApplication.value === RangeOfApplication.FACADE,
  );
  const withLockingDrive = useSelector<IState, boolean>(
    state =>
      state.parameters.lockingDrive.value!.toString() !==
      LockingDrive.WITHOUT_LOCKING_DRIVE,
  );

  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const { formatMessage } = useIntl();
  const systemSeriesResult = useSelector(
    withLockingDrive
      ? (state: IState) =>
          state.profileData.systemSeriesResult.filter(
            systemSeries => systemSeries.allowsLocking,
          )
      : (state: IState) => state.profileData.systemSeriesResult,
  );
  const sashProfiles = useSelector<IState, SashProfile[]>(
    s => s.profileData.sashProfilesResult,
  );
  const frameProfiles = useSelector<IState, FrameProfile[]>(
    s => s.profileData.frameProfilesResult,
  );
  const exchangeProfiles = useSelector<IState, ExchangeProfile[]>(
    s => s.profileData.exchangeProfilesResult,
  );
  const baseProfiles = useSelector<IState, BaseProfile[]>(
    s => s.profileData.baseProfilesResult,
  );
  const selectedProfileSystem = useSelector<IState, ParameterValue>(
    s => s.parameters.profileSystem,
  );
  const selectedProfileSeries = useSelector<IState, ParameterValue>(
    s => s.parameters.profileSeries,
  );
  const selectedSashProfile = useSelector<IState, SashProfile | undefined>(
    s => {
      return s.profileData.sashProfilesResult.find(
        sp => sp.itemNumber === s.parameters.profileSash.value,
      );
    },
  );
  const selectedFrameProfile = useSelector<IState, FrameProfile | undefined>(
    s =>
      s.profileData.frameProfilesResult.find(
        fp => fp.itemNumber === s.parameters.profileFrame.value,
      ),
  );
  const selectedExchangeProfile = useSelector<
    IState,
    ExchangeProfile | undefined
  >(s =>
    s.profileData.exchangeProfilesResult.find(
      ep => ep.itemNumber === s.parameters.profileExchange.value,
    ),
  );
  const selectedBaseProfile = useSelector<IState, BaseProfile | undefined>(s =>
    s.profileData.baseProfilesResult.find(
      bp => bp.itemNumber === s.parameters.profileBase.value,
    ),
  );
  const markedSeries = useSelector<IState, SystemSeries | undefined>(
    s => s.ui.markedSeries,
  );
  const markedSashProfile = useSelector<IState, SashProfile | undefined>(
    s => s.ui.markedSashProfile,
  );
  const markedFrameProfile = useSelector<IState, FrameProfile | undefined>(
    s => s.ui.markedFrameProfile,
  );
  const markedExchangeProfile = useSelector<
    IState,
    ExchangeProfile | undefined
  >(s => s.ui.markedExchangeProfile);
  const markedBaseProfile = useSelector<IState, BaseProfile | undefined>(
    s => s.ui.markedBaseProfile,
  );

  useEffect(() => {
    dispatch(
      updateMarkedSeries(
        systemSeriesResult.find(
          s =>
            s.system === selectedProfileSystem.value &&
            s.series === selectedProfileSeries.value,
        ),
      ),
    );
    dispatch(setMarkedSashProfile(selectedSashProfile));
    dispatch(setMarkedFrameProfile(selectedFrameProfile));
    dispatch(setMarkedExchangeProfile(selectedExchangeProfile));
    dispatch(setMarkedBaseProfile(selectedBaseProfile));

    return () => {
      dispatch(updateMarkedSeries(undefined));
      dispatch(setMarkedSashProfile(undefined));
      dispatch(setMarkedFrameProfile(undefined));
      dispatch(setMarkedBaseProfile(undefined));
      dispatch(setMarkedSashProfile(undefined));
    };
  }, [dispatch]);

  function saveSystemAndSeries(): void {
    if (markedSeries) {
      dispatch(showDialog(undefined));
      dispatch(updateSystemSeries(markedSeries));
    }
  }

  function getAdditionalInformationElementForFrameProfile(
    f: FrameProfile,
  ): ReactNode {
    return (
      <>
        {' '}
        {f.baseProfilePossible && (
          <span>
            {formatMessage({
              id: 'PROFILE_WIZARD_INFORMATION_BASE_PROFILE',
            })}
            ,&nbsp;
          </span>
        )}
        <span
          className={
            f.exchangeProfileRequired
              ? 'system-series-dialog__profile-exchange'
              : 'system-series-dialog__profile-no-exchange'
          }
        >
          {informationTextExchangeProfile(f)}
        </span>
      </>
    );
  }

  function saveProfileCombination(): void {
    dispatch(showDialog(undefined));
    dispatch(
      updateProfileData(
        markedSeries!,
        markedSashProfile!,
        markedFrameProfile!,
        markedExchangeProfile,
        markedBaseProfile,
      ),
    );
  }

  function saveButtonDisabled(): boolean {
    if (!props.profileCombination) {
      return !markedSeries;
    }

    function everythingSetIfExchangeProfileRequired(): boolean {
      return !!(
        markedSeries &&
        markedSashProfile &&
        markedFrameProfile &&
        markedExchangeProfile &&
        markedFrameProfile?.exchangeProfileRequired
      );
    }

    function everythingSetIfExchangeProfileNotRequired(): boolean {
      return !!(
        markedSeries &&
        markedSashProfile &&
        markedFrameProfile &&
        !markedFrameProfile?.exchangeProfileRequired
      );
    }

    return (
      !everythingSetIfExchangeProfileNotRequired() &&
      !everythingSetIfExchangeProfileRequired()
    );
  }

  function informationTextExchangeProfile(s: FrameProfile): string {
    return (
      formatMessage({
        id: s.exchangeProfileRequired
          ? 'PROFILE_WIZARD_INFORMATION_WITH_EXCHANGE_PROFILE'
          : 'PROFILE_WIZARD_INFORMATION_WITHOUT_EXCHANGE_PROFILE',
      }) || ''
    );
  }

  function assemblySpaceWithUnit(s: SashProfile): string {
    return String(s.assemblySpace).concat(
      ' ' + formatMessage({ id: 'client_calculation_hubType' }),
    );
  }

  function resultTable(): ReactNode | null {
    if (!markedSeries || !props.profileCombination) {
      return (
        <SystemSeriesResultTable<SystemSeries>
          key="system_series"
          heading="POSSIBLE_SYSTEM_AND_SERIES"
          setMarkedEntry={(s: SystemSeries) => dispatch(updateMarkedSeries(s))}
          markedEntry={markedSeries}
          profileCombination={props.profileCombination}
          columnTitles={[
            'tree_parent_system_serie',
            'tree_parent_system_system',
            'fenster_material_label',
          ]}
          placeHolder="SEARCHING_FOR_SYSTEM_AND_SERIES"
          entries={systemSeriesResult}
          toSearchTuple={(s: SystemSeries) => {
            return [
              s.system.toLowerCase(),
              s.series.toLowerCase(),
              formatMessage({ id: s.material }).toLowerCase(),
            ];
          }}
          toRow={(s: SystemSeries) => [
            s.series,
            s.system,
            formatMessage({ id: s.material }),
          ]}
        />
      );
    }

    if (!markedSashProfile && markedSeries) {
      return (
        <SystemSeriesResultTable<SashProfile>
          key="sash_profiles"
          heading="POSSIBLE_SASH_PROFILES"
          setMarkedEntry={(s: SashProfile) => dispatch(setMarkedSashProfile(s))}
          markedEntry={markedSashProfile}
          profileCombination={props.profileCombination}
          columnTitles={
            isFacade
              ? ['fenster_edit_fluegelprofil', 'TYPE_ASSEMBLY_SPACE']
              : ['fenster_edit_fluegelprofil']
          }
          placeHolder="SEARCHING_FOR_SASH_PROFILE"
          entries={sashProfiles}
          toSearchTuple={(s: SashProfile) => {
            return [s.itemNumber.toLocaleLowerCase()];
          }}
          toRow={(s: SashProfile) =>
            isFacade ? [s.itemNumber, assemblySpaceWithUnit(s)] : [s.itemNumber]
          }
        />
      );
    }

    if (props.profileCombination && !markedFrameProfile) {
      return (
        <SystemSeriesResultTable<FrameProfile>
          key="frame_profiles"
          heading="POSSIBLE_FRAME_PROFILES"
          setMarkedEntry={(s: FrameProfile) =>
            dispatch(setMarkedFrameProfile(s))
          }
          markedEntry={markedFrameProfile}
          profileCombination={props.profileCombination}
          columnTitles={
            isFacade
              ? [
                  'fenster_edit_blendrahmen',
                  'TYPE_ASSEMBLY_SPACE',
                  'PROFILE_WIZARD_INFORMATION_HEADING',
                ]
              : [
                  'fenster_edit_blendrahmen',
                  'PROFILE_WIZARD_INFORMATION_HEADING',
                ]
          }
          placeHolder="SEARCHING_FOR_FRAME_PROFILE"
          entries={frameProfiles}
          toSearchTuple={(s: FrameProfile) => {
            return [
              s.itemNumber.toLocaleLowerCase(),
              informationTextExchangeProfile(s) +
                (s.baseProfilePossible
                  ? formatMessage({
                      id: 'PROFILE_WIZARD_INFORMATION_BASE_PROFILE',
                    })
                  : ''),
            ];
          }}
          toRow={(f: FrameProfile) =>
            isFacade
              ? [
                  f.itemNumber,
                  assemblySpaceWithUnit(f),
                  getAdditionalInformationElementForFrameProfile(f),
                ]
              : [
                  f.itemNumber,
                  getAdditionalInformationElementForFrameProfile(f),
                ]
          }
        />
      );
    }
    if (
      props.profileCombination &&
      markedFrameProfile?.exchangeProfileRequired &&
      !markedExchangeProfile
    ) {
      return (
        <SystemSeriesResultTable<ExchangeProfile>
          key="exchange_profiles"
          heading="POSSIBLE_EXCHANGE_PROFILES"
          setMarkedEntry={(e: ExchangeProfile) =>
            dispatch(setMarkedExchangeProfile(e))
          }
          markedEntry={markedExchangeProfile}
          profileCombination={props.profileCombination}
          columnTitles={
            isFacade
              ? ['fenster_edit_wechselprofil', 'TYPE_ASSEMBLY_SPACE']
              : ['fenster_edit_wechselprofil']
          }
          placeHolder="SEARCHING_FOR_EXCHANGE_PROFILE"
          entries={exchangeProfiles}
          toSearchTuple={(s: ExchangeProfile) => {
            return [s.itemNumber.toLocaleLowerCase()];
          }}
          toRow={(s: ExchangeProfile) =>
            isFacade ? [s.itemNumber, assemblySpaceWithUnit(s)] : [s.itemNumber]
          }
        />
      );
    }
    if (
      markedFrameProfile?.baseProfilePossible &&
      !markedBaseProfile &&
      ((markedFrameProfile.exchangeProfileRequired && markedExchangeProfile) ||
        !markedFrameProfile?.exchangeProfileRequired)
    ) {
      return (
        <SystemSeriesResultTable<BaseProfile>
          key="base_profiles"
          heading="POSSIBLE_BASE_PROFILES"
          setMarkedEntry={(s: BaseProfile) => dispatch(setMarkedBaseProfile(s))}
          markedEntry={markedBaseProfile}
          profileCombination={props.profileCombination}
          columnTitles={
            isFacade
              ? ['fenster_edit_grundprofil', 'TYPE_ASSEMBLY_SPACE']
              : ['fenster_edit_grundprofil']
          }
          placeHolder="SEARCHING_FOR_BASE_PROFILE"
          entries={baseProfiles}
          toSearchTuple={(s: BaseProfile) => {
            return [s.itemNumber.toLocaleLowerCase()];
          }}
          toRow={(s: BaseProfile) =>
            isFacade ? [s.itemNumber, assemblySpaceWithUnit(s)] : [s.itemNumber]
          }
        />
      );
    }

    return (
      <div className="system-series-dialog__sucess-state">
        <div className="system-series-dialog__sucess-icon">
          <IconSucess />
        </div>
        <div className="system-series-dialog__sucess-text">
          <FormattedMessage id="PROFILE_COMBINATION_SUCESS_TEXT" />
        </div>
      </div>
    );
  }

  return (
    <DialogFE
      heading={
        !props.profileCombination
          ? 'ADD_SYSTEM_AND_SERIES'
          : 'PROFILE_COMBINATION'
      }
      componentClass=""
      footerProps={{
        primaryActionLabel: props.profileCombination
          ? 'FINISH'
          : 'BUTTON_LABEL_ADD',
        saveAction: saveButtonDisabled()
          ? undefined
          : props.profileCombination
          ? saveProfileCombination
          : saveSystemAndSeries,
        cancelAction: true,
      }}
    >
      <div className="system-series-dialog system-series-dialog--wizard">
        {props.profileCombination ? (
          <NavigationBar
            markedSeries={markedSeries}
            markedSashProfile={markedSashProfile}
            markedFrameProfile={markedFrameProfile}
            markedExchangeProfile={markedExchangeProfile}
            markedBaseProfile={markedBaseProfile}
            resetMarkedSystem={() => dispatch(updateMarkedSeries(undefined))}
            resetMarkedSashProfile={() => {
              dispatch(setMarkedSashProfile(undefined));
              dispatch(setMarkedFrameProfile(undefined));
              dispatch(setMarkedExchangeProfile(undefined));
              dispatch(setMarkedBaseProfile(undefined));
            }}
            resetMarkedFrameProfile={() => {
              dispatch(setMarkedFrameProfile(undefined));
              dispatch(setMarkedExchangeProfile(undefined));
              dispatch(setMarkedBaseProfile(undefined));
            }}
            resetMarkedExchangeProfile={() => {
              dispatch(setMarkedExchangeProfile(undefined));
              dispatch(setMarkedBaseProfile(undefined));
            }}
            resetMarkedBaseProfile={() =>
              dispatch(setMarkedBaseProfile(undefined))
            }
          />
        ) : null}
        {resultTable()}
      </div>
    </DialogFE>
  );
};

export default SystemSeriesDialog;
