import React, { FC, useState } from 'react';
import FormLayout from '../../elements/FormLayout';
import InputField, { InputFieldNumber } from '../../elements/InputField';
import TextArea from '../../elements/TextArea';
import './GeneralDrivesDataView.scss';
import { SelectField, selectFieldValue } from '../../elements/SelectField';
import Dialog from '../../components/Dialog';
import Table from '../components/Table';
import TableHeader from '../elements/TableHeader';
import TableRow from '../elements/TableRow';
import SearchField from '../../elements/SearchField';
import { Switch } from '../../elements/Switch';
import { Navigate, Route, Routes } from 'react-router-dom';
import CheckBox from '../../elements/CheckBox';
import AdminListIcon from '../elements/AdminListIcon';
import { useDispatch, useSelector } from 'react-redux';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import {
  AdminDrive,
  DriveFamily,
  NewAdminDrive,
  NewStrokeType,
  PowerAndpowerConsumption,
  StrokeType,
} from '../../redux/admin/adminFacadeReducer';
import {
  changeAdminDrive,
  createAdminDrive,
  updateEditedDrive,
  updateEditedStrokeType,
} from '../../redux/admin/adminFacadeActions';
import { conditionalValue, fieldsFilled, findById } from '../general/helpers';
import { UiConstants } from '../constants';
import Pagination from '../components/Pagination';
import AdminRadioButton from '../../elements/RadioButton';
import Button, { ButtonType } from '../../elements/Button';
import { AnyAction } from 'redux';
import AdminNavLink from '../common/AdminNavLink';
import { useAdminSearch } from '../hooks';
import { Edit } from '../../types';
import FormLayoutSubgroupTitle from '../../elements/FormLayoutSubgroupTitle';
import classNames from 'classnames';
import { NrwgTestTypes } from '../../redux/constants';

function useEditedDrive(): Edit<AdminDrive> | Edit<NewAdminDrive> {
  return useSelector<AdminState, Edit<AdminDrive> | Edit<NewAdminDrive>>(
    state => state.adminFacade.editedDrive!,
  );
}

const DrivesDataDialogTabNav: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="admin__tab-nav test-phases__tab-nav">
      <AdminNavLink to="/admin/general-data/drives/basic">
        <Routes>
          <Route
            path="/"
            element={<Navigate replace to="/admin/general-data/drives/basic" />}
          />
        </Routes>
        Allgemein
      </AdminNavLink>
      <AdminNavLink to="/admin/general-data/drives/basic-information">
        Eckdaten
      </AdminNavLink>
      <AdminNavLink to="/admin/general-data/drives/stroke-types">
        Hub-Typen
      </AdminNavLink>
      <AdminNavLink to="/admin/general-data/drives/drive-stroke-variants">
        Antriebs-Hub-Varianten
      </AdminNavLink>
    </div>
  );
};

interface DrivesDialogProps {
  setDialogIsShown: (b: boolean) => void;
  dialogIsShown: boolean;
}

const DrivesDialog: FC<React.PropsWithChildren<DrivesDialogProps>> = props => {
  const editedDrive = useEditedDrive();
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  if (!editedDrive) {
    return null;
  }

  function mandatoryFieldsFilled(): boolean {
    return (
      fieldsFilled(
        editedDrive?.artikelNr,
        editedDrive?.bezeichnung,
        editedDrive?.antriebsfamilie,
        editedDrive?.anzahlAngriffspunkte,
        editedDrive?.breiteKurzeSeite,
        editedDrive?.maxAnzahlAntriebe,
        conditionalValue(
          editedDrive.powerAndPowerConsumption !==
            PowerAndpowerConsumption.STROKE_VARIANTS,
          editedDrive?.power,
        ),
        conditionalValue(
          editedDrive.powerAndPowerConsumption !==
            PowerAndpowerConsumption.STROKE_VARIANTS,
          editedDrive?.powerConsumption,
        ),
        editedDrive?.druckKraft,
        editedDrive?.zugKraft,
        editedDrive?.nennverriegelungsKraft,
        editedDrive?.standardLaufzeit,
        conditionalValue(
          editedDrive.powerAndPowerConsumption ===
            PowerAndpowerConsumption.HIGH_SPEED,
          editedDrive?.highSpeedLaufzeit,
        ),
        conditionalValue(
          editedDrive.powerAndPowerConsumption ===
            PowerAndpowerConsumption.HIGH_SPEED,
          editedDrive?.highSpeedPower,
        ),
        conditionalValue(
          editedDrive.powerAndPowerConsumption ===
            PowerAndpowerConsumption.HIGH_SPEED,
          editedDrive?.highSpeedPowerConsumption,
        ),
        ...(editedDrive?.antriebsHubVarianten?.map(av =>
          conditionalValue(
            editedDrive.powerAndPowerConsumption ===
              PowerAndpowerConsumption.STROKE_VARIANTS,
            av.power,
          ),
        ) || []),
        ...(editedDrive?.antriebsHubVarianten?.map(av =>
          conditionalValue(
            editedDrive.powerAndPowerConsumption ===
              PowerAndpowerConsumption.STROKE_VARIANTS,
            av.powerConsumption,
          ),
        ) || []),
        ...(editedDrive?.hubtypen?.map(h => h.elementgroesse) || []),
        ...(editedDrive?.antriebsHubVarianten?.map(av => av.breite) || []),
        ...(editedDrive?.antriebsHubVarianten?.map(av => av.maxHub) || []),
        ...(editedDrive?.antriebsHubVarianten?.map(av => av.gewicht) || []),
        ...(editedDrive?.antriebsHubVarianten?.map(av => av.tiefe) || []),
        ...(editedDrive?.antriebsHubVarianten?.map(av => av.laenge) || []),
        ...(editedDrive?.hubtypen?.map(h => h.elementgroesse) || []),
        ...(editedDrive?.hubtypen?.flatMap(h =>
          h.drucklastPunkte.map(dp => dp.x && dp.y),
        ) || []),
      ) && !!(editedDrive?.volt24 || editedDrive?.volt230)
    );
  }

  return (
    <Dialog
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      headingText={
        'id' in editedDrive && editedDrive.id
          ? 'Antrieb bearbeiten'
          : 'Antrieb anlegen'
      }
      componentClass="general-drives-data-dialog full-view-dialog"
      key={props.dialogIsShown.toString()}
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          props.setDialogIsShown(false);
        },
        saveAction: mandatoryFieldsFilled()
          ? () => {
              dispatch(
                'id' in editedDrive && editedDrive.id
                  ? changeAdminDrive(editedDrive as AdminDrive)
                  : createAdminDrive(editedDrive as NewAdminDrive),
              );
              props.setDialogIsShown(false);
            }
          : undefined,
        primaryActionLabelText:
          'id' in editedDrive && editedDrive.id ? 'Speichern' : 'Anlegen',
      }}
    >
      <DrivesDataDialogTabNav />
      <div>
        <Routes>
          <Route path="/basic/*" element={<GeneralDrivesDataDialogBasic />} />
          <Route
            path="/basic-information/*"
            element={<GeneralDrivesDataDialogBasicInformation />}
          />
          <Route
            path="/stroke-types/*"
            element={<GeneralDrivesDataDialogStrokeTypes />}
          />
          <Route
            path="/drive-stroke-variants/*"
            element={<GeneralDrivesDataDialogDriveStrokeVariants />}
          />
        </Routes>
      </div>
    </Dialog>
  );
};

const GeneralDrivesDataDialogBasic: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const editedDrive = useEditedDrive();
  const driveFamilies = useSelector<AdminState, DriveFamily[]>(
    state => state.adminFacade.driveFamilies,
  );
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  if (!editedDrive) {
    return null;
  }

  return (
    <FormLayout additionalClass="general-drives-data-dialog__layout-basic">
      <InputField
        label="Artikelnummer*"
        placeholder="Artikelnummer"
        value={editedDrive.artikelNr}
        additionalClass="general-drives-data-dialog__article-nr"
        onChange={v =>
          dispatch(updateEditedDrive({ ...editedDrive, artikelNr: v }))
        }
      />
      <InputField
        label="Bezeichnung*"
        placeholder="Bezeichnung"
        value={editedDrive.bezeichnung}
        additionalClass="general-drives-data-dialog__label"
        onChange={v =>
          dispatch(updateEditedDrive({ ...editedDrive, bezeichnung: v }))
        }
      />
      <TextArea
        label="Info"
        additionalClass="general-drives-data-dialog__info"
        placeholder="Informationen zum System"
        value={editedDrive.info || ''}
        onChange={v => dispatch(updateEditedDrive({ ...editedDrive, info: v }))}
      />
      <SelectField
        additionalClass="general-drives-data-dialog__family"
        label="Antriebsfamilie *"
        value={
          editedDrive.antriebsfamilie &&
          selectFieldValue(
            editedDrive.antriebsfamilie.antriebsfamilie,
            editedDrive.antriebsfamilie.id,
          )
        }
        action={v =>
          dispatch(
            updateEditedDrive({
              ...editedDrive,
              antriebsfamilie: findById(v.value, driveFamilies),
            }),
          )
        }
        options={driveFamilies.map(d =>
          selectFieldValue(d.antriebsfamilie, d.id),
        )}
        name="project-country"
        searchable={true}
      />
      <div className="general-drives-data-dialog__active">
        <Switch
          labelText="Aktiv"
          turnedOn={!!editedDrive.active}
          onChange={v =>
            dispatch(updateEditedDrive({ ...editedDrive, active: v }))
          }
        />
      </div>
      <div className="general-drives-data-dialog__preview">
        <Switch
          labelText="Vorschau"
          turnedOn={!!editedDrive.preview}
          onChange={v =>
            dispatch(updateEditedDrive({ ...editedDrive, preview: v }))
          }
        />
      </div>
      <div className="general-drives-data-dialog__scheme">
        <Switch
          labelText="Antriebsnamen nach neuem Schema generieren"
          turnedOn={!!editedDrive.neuAb2016}
          onChange={v =>
            dispatch(updateEditedDrive({ ...editedDrive, neuAb2016: v }))
          }
        />
      </div>
      <div className="general-drives-data-dialog__tested-facade">
        <FormLayoutSubgroupTitle>
          NRWG Prüfung & Zertifikat Fassade
        </FormLayoutSubgroupTitle>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <AdminRadioButton
            checked={
              editedDrive.nrwgTestTypeFacade === NrwgTestTypes.UNTESTED_NO_CERT
            }
            name="Antrieb nicht für NRWG geprüft, nicht im Zertifikat vorhanden"
            id="facadeUntested"
            onChange={() => {
              dispatch(
                updateEditedDrive({
                  ...editedDrive,
                  nrwgTestTypeFacade: NrwgTestTypes.UNTESTED_NO_CERT,
                }),
              );
            }}
          />
          <AdminRadioButton
            checked={
              editedDrive.nrwgTestTypeFacade === NrwgTestTypes.TESTED_NO_CERT
            }
            name="Antrieb für NRWG geprüft, nicht im Zertifikat vorhanden"
            id="facadeInProgress"
            onChange={() => {
              dispatch(
                updateEditedDrive({
                  ...editedDrive,
                  nrwgTestTypeFacade: NrwgTestTypes.TESTED_NO_CERT,
                }),
              );
            }}
          />
          <AdminRadioButton
            checked={
              editedDrive.nrwgTestTypeFacade === NrwgTestTypes.TESTED_AND_CERT
            }
            name="Antrieb für NRWG geprüft, im Zertifikat vorhanden"
            id="facadeTested"
            onChange={() => {
              dispatch(
                updateEditedDrive({
                  ...editedDrive,
                  nrwgTestTypeFacade: NrwgTestTypes.TESTED_AND_CERT,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="general-drives-data-dialog__tested-roof">
        <FormLayoutSubgroupTitle>
          NRWG Prüfung & Zertifikat Dach
        </FormLayoutSubgroupTitle>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <AdminRadioButton
            checked={
              editedDrive.nrwgTestTypeRoof === NrwgTestTypes.UNTESTED_NO_CERT
            }
            name="Antrieb nicht für NRWG geprüft, nicht im Zertifikat vorhanden"
            id="roofUntested"
            onChange={() => {
              dispatch(
                updateEditedDrive({
                  ...editedDrive,
                  nrwgTestTypeRoof: NrwgTestTypes.UNTESTED_NO_CERT,
                }),
              );
            }}
          />
          <AdminRadioButton
            checked={
              editedDrive.nrwgTestTypeRoof === NrwgTestTypes.TESTED_NO_CERT
            }
            name="Antrieb für NRWG geprüft, nicht im Zertifikat vorhanden"
            id="roofInProgress"
            onChange={() => {
              dispatch(
                updateEditedDrive({
                  ...editedDrive,
                  nrwgTestTypeRoof: NrwgTestTypes.TESTED_NO_CERT,
                }),
              );
            }}
          />
          <AdminRadioButton
            checked={
              editedDrive.nrwgTestTypeRoof === NrwgTestTypes.TESTED_AND_CERT
            }
            name="Antrieb für NRWG geprüft, im Zertifikat vorhanden"
            id="roofTested"
            onChange={() => {
              dispatch(
                updateEditedDrive({
                  ...editedDrive,
                  nrwgTestTypeRoof: NrwgTestTypes.TESTED_AND_CERT,
                }),
              );
            }}
          />
        </div>
      </div>
    </FormLayout>
  );
};

const GeneralDrivesDataDialogBasicInformation: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const editedDrive = useEditedDrive();
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  return (
    <FormLayout
      additionalClass={classNames(
        'general-drives-data-dialog__layout-basic-information',
        {
          'general-drives-data-dialog__layout-basic-information--stroke-variants':
            editedDrive.powerAndPowerConsumption ===
            PowerAndpowerConsumption.STROKE_VARIANTS,
        },
      )}
    >
      <div className="general-drives-data-dialog__voltage">
        <FormLayoutSubgroupTitle>Spannung *</FormLayoutSubgroupTitle>
        <AdminRadioButton
          checked={!!editedDrive?.volt24}
          name="24 V"
          onChange={() =>
            dispatch(
              updateEditedDrive({
                ...editedDrive,
                volt24: true,
                volt230: false,
              }),
            )
          }
        />
        <AdminRadioButton
          checked={!!editedDrive?.volt230}
          name="230 V"
          onChange={() =>
            dispatch(
              updateEditedDrive({
                ...editedDrive,
                volt24: false,
                volt230: true,
              }),
            )
          }
        />
      </div>
      <div className="general-drives-data-dialog__type">
        <FormLayoutSubgroupTitle>Type *</FormLayoutSubgroupTitle>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <AdminRadioButton
            checked={
              editedDrive?.powerAndPowerConsumption ===
              PowerAndpowerConsumption.STANDARD
            }
            name="Standard"
            onChange={() =>
              dispatch(
                updateEditedDrive({
                  ...editedDrive,
                  powerAndPowerConsumption: PowerAndpowerConsumption.STANDARD,
                }),
              )
            }
          />
          <AdminRadioButton
            checked={
              editedDrive?.powerAndPowerConsumption ===
              PowerAndpowerConsumption.HIGH_SPEED
            }
            name="High Speed"
            onChange={() =>
              dispatch(
                updateEditedDrive({
                  ...editedDrive,
                  powerAndPowerConsumption: PowerAndpowerConsumption.HIGH_SPEED,
                }),
              )
            }
          />
          <AdminRadioButton
            checked={
              editedDrive?.powerAndPowerConsumption ===
              PowerAndpowerConsumption.STROKE_VARIANTS
            }
            name="Stroke Variants"
            onChange={() =>
              dispatch(
                updateEditedDrive({
                  ...editedDrive,
                  powerAndPowerConsumption:
                    PowerAndpowerConsumption.STROKE_VARIANTS,
                }),
              )
            }
          />
        </div>
      </div>
      <InputFieldNumber
        label="Std. Laufzeit (mm/sek) *"
        placeholder=""
        value={editedDrive?.standardLaufzeit}
        additionalClass="general-drives-data-dialog__laufzeit"
        onChange={v =>
          dispatch(updateEditedDrive({ ...editedDrive, standardLaufzeit: v }))
        }
      />
      {editedDrive.powerAndPowerConsumption ===
        PowerAndpowerConsumption.HIGH_SPEED && (
        <>
          <InputFieldNumber
            label="High Speed Laufzeit (mm/sek) *"
            placeholder=""
            value={editedDrive?.highSpeedLaufzeit}
            additionalClass="general-drives-data-dialog__high-speed-laufzeit"
            onChange={v =>
              dispatch(
                updateEditedDrive({ ...editedDrive, highSpeedLaufzeit: v }),
              )
            }
          />
        </>
      )}
      {editedDrive.powerAndPowerConsumption !==
        PowerAndpowerConsumption.STROKE_VARIANTS && (
        <>
          <InputFieldNumber
            label="Strohmaufnahme (A) *"
            placeholder=""
            value={editedDrive?.powerConsumption}
            additionalClass="general-drives-data-dialog__power-consumption"
            onChange={v =>
              dispatch(
                updateEditedDrive({ ...editedDrive, powerConsumption: v }),
              )
            }
          />
          <InputFieldNumber
            label="Leistung (W) *"
            placeholder=""
            value={editedDrive?.power}
            additionalClass="general-drives-data-dialog__power"
            onChange={v =>
              dispatch(updateEditedDrive({ ...editedDrive, power: v }))
            }
          />
          {editedDrive.powerAndPowerConsumption ===
            PowerAndpowerConsumption.HIGH_SPEED && (
            <>
              <InputFieldNumber
                label="High Speed Strohmaufnahme (A) *"
                placeholder=""
                value={editedDrive?.highSpeedPowerConsumption}
                additionalClass="general-drives-data-dialog__high-speed-power-consumption"
                onChange={v =>
                  dispatch(
                    updateEditedDrive({
                      ...editedDrive,
                      highSpeedPowerConsumption: v,
                    }),
                  )
                }
              />
              <InputFieldNumber
                label="High Speed Leistung (W) *"
                placeholder=""
                value={editedDrive?.highSpeedPower}
                additionalClass="general-drives-data-dialog__high-speed-power"
                onChange={v =>
                  dispatch(
                    updateEditedDrive({ ...editedDrive, highSpeedPower: v }),
                  )
                }
              />
            </>
          )}
        </>
      )}

      <InputFieldNumber
        label="Druckkraft (N) *"
        placeholder=""
        value={editedDrive?.druckKraft}
        additionalClass="general-drives-data-dialog__push-force"
        onChange={v =>
          dispatch(updateEditedDrive({ ...editedDrive, druckKraft: v }))
        }
      />
      <InputFieldNumber
        label="Zugkraft (N) *"
        placeholder=""
        value={editedDrive?.zugKraft}
        additionalClass="general-drives-data-dialog__pull-force"
        onChange={v =>
          dispatch(updateEditedDrive({ ...editedDrive, zugKraft: v }))
        }
      />
      <InputFieldNumber
        label="Nennverriegelungskraft (N) *"
        placeholder=""
        value={editedDrive?.nennverriegelungsKraft}
        additionalClass="general-drives-data-dialog__holding-force"
        onChange={v =>
          dispatch(
            updateEditedDrive({ ...editedDrive, nennverriegelungsKraft: v }),
          )
        }
      />
      <InputFieldNumber
        label="Abschaltreserve Druckkraft (N)"
        placeholder=""
        value={editedDrive?.abschaltreserveDruckKraft}
        additionalClass="general-drives-data-dialog__reserve-push-force"
        onChange={v =>
          dispatch(
            updateEditedDrive({ ...editedDrive, abschaltreserveDruckKraft: v }),
          )
        }
      />
      <InputFieldNumber
        label="Abschaltreserve Zugkraft (N)"
        placeholder=""
        value={editedDrive?.abschaltreserveZugKraft}
        additionalClass="general-drives-data-dialog__reserve-pull-force"
        onChange={v =>
          dispatch(
            updateEditedDrive({ ...editedDrive, abschaltreserveZugKraft: v }),
          )
        }
      />

      <InputFieldNumber
        label="Anzahl der Angriffspunkte *"
        placeholder=""
        value={editedDrive?.anzahlAngriffspunkte}
        additionalClass="general-drives-data-dialog__points-amount"
        onChange={v =>
          dispatch(
            updateEditedDrive({ ...editedDrive, anzahlAngriffspunkte: v }),
          )
        }
      />
      <InputFieldNumber
        label="Breite kurze Seite (mm) *"
        placeholder=""
        value={editedDrive?.breiteKurzeSeite}
        additionalClass="general-drives-data-dialog__broad-side"
        onChange={v =>
          dispatch(updateEditedDrive({ ...editedDrive, breiteKurzeSeite: v }))
        }
      />
      <InputFieldNumber
        label="Max. Anzahl der Antriebe *"
        placeholder=""
        value={editedDrive?.maxAnzahlAntriebe}
        additionalClass="general-drives-data-dialog__max-amount"
        onChange={v =>
          dispatch(updateEditedDrive({ ...editedDrive, maxAnzahlAntriebe: v }))
        }
      />
    </FormLayout>
  );
};

interface StrokeTypeDialogProps {
  setDialogIsShown: (b: boolean) => void;
  dialogIsShown: boolean;
}

const StrokeTypeDialog: FC<
  React.PropsWithChildren<StrokeTypeDialogProps>
> = props => {
  const editedDrive = useEditedDrive();
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const editedStrokeType = useSelector<
    AdminState,
    Edit<StrokeType | NewStrokeType> & { index: number | undefined }
  >(state => state.adminFacade.editedStrokeType!);

  if (!editedStrokeType) {
    return null;
  }

  function mandatoryFieldsFilled(): boolean {
    return (
      fieldsFilled(
        editedStrokeType?.bezeichnung,
        editedStrokeType?.elementgroesse,
        ...(editedStrokeType?.drucklastPunkte?.flatMap(p => [p.x, p.y]) || []),
      ) && (editedStrokeType?.drucklastPunkte?.length || 0) > 0
    );
  }

  function updateEditStrokeTypeInEditedDrive(): void {
    const index = editedStrokeType!.index;
    const updatedStrokeTypeList = editedDrive!.hubtypen || [];
    if (index === undefined) {
      updatedStrokeTypeList.push(editedStrokeType as NewStrokeType);
      dispatch(
        updateEditedDrive({ ...editedDrive, hubtypen: updatedStrokeTypeList }),
      );
    } else {
      delete editedStrokeType.index;
      updatedStrokeTypeList.splice(index, 1, {
        ...(editedStrokeType as NewStrokeType),
      });
      dispatch(
        updateEditedDrive({ ...editedDrive, hubtypen: updatedStrokeTypeList }),
      );
    }
  }

  function updatePointX(v: number | null, i: number): void {
    const dps = editedStrokeType!.drucklastPunkte!;
    dps.splice(i, 1, {
      ...editedStrokeType!.drucklastPunkte![i],
      x: v,
    });
    dispatch(
      updateEditedStrokeType({
        ...editedStrokeType,
        drucklastPunkte: dps,
      }),
    );
  }

  function updatePointY(v: number | null, i: number): void {
    const dps = editedStrokeType!.drucklastPunkte!;
    dps.splice(i, 1, {
      ...editedStrokeType!.drucklastPunkte![i],
      y: v,
    });
    dispatch(
      updateEditedStrokeType({
        ...editedStrokeType,
        drucklastPunkte: dps,
      }),
    );
  }

  function deletePoint(i: number): void {
    const dps = editedStrokeType!.drucklastPunkte!;
    dps.splice(i, 1);
    dispatch(
      updateEditedStrokeType({
        ...editedStrokeType,
        drucklastPunkte: dps,
      }),
    );
  }

  function addPoint(): void {
    const dps = editedStrokeType!.drucklastPunkte || [];
    dps.push({ x: null, y: null });

    dispatch(
      updateEditedStrokeType({
        ...editedStrokeType,
        drucklastPunkte: dps,
      }),
    );
  }

  return (
    <Dialog
      nested={true}
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      headingText={
        'id' in editedStrokeType && editedStrokeType.id
          ? 'Hubtyp bearbeiten'
          : 'Hubtyp anlegen'
      }
      componentClass="sub-dialog"
      key={'stroke-types' + props.dialogIsShown.toString()}
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          props.setDialogIsShown(false);
        },
        saveAction: mandatoryFieldsFilled()
          ? () => {
              updateEditStrokeTypeInEditedDrive();
              props.setDialogIsShown(false);
            }
          : undefined,
        primaryActionLabelText: editedStrokeType.index
          ? 'Speichern'
          : 'Anlegen',
      }}
    >
      <FormLayout additionalClass="general-drives-data-dialog__stroke-type-dialog">
        <InputField
          label="Bezeichnung *"
          placeholder=""
          value={editedStrokeType.bezeichnung}
          additionalClass="general-drives-data-dialog__stroke-label"
          onChange={v =>
            dispatch(
              updateEditedStrokeType({ ...editedStrokeType, bezeichnung: v }),
            )
          }
        />
        <InputFieldNumber
          label="Elementgröße (mm)*"
          placeholder=""
          value={editedStrokeType.elementgroesse}
          additionalClass="general-drives-data-dialog__stroke-size"
          onChange={v =>
            dispatch(
              updateEditedStrokeType({
                ...editedStrokeType,
                elementgroesse: v,
              }),
            )
          }
        />
        <div className="form-layout__sub-group form-layout__sub-group--2 general-drives-data-dialog__drive-series">
          <FormLayoutSubgroupTitle>Antriebsart</FormLayoutSubgroupTitle>
          <CheckBox
            label="Kette"
            checked={!!editedStrokeType.kette}
            onClick={() =>
              dispatch(
                updateEditedStrokeType({
                  ...editedStrokeType,
                  kette: !editedStrokeType?.kette,
                }),
              )
            }
          />
          <CheckBox
            label="Zahnstange"
            checked={!editedStrokeType.kette}
            onClick={() =>
              dispatch(
                updateEditedStrokeType({
                  ...editedStrokeType,
                  kette: !editedStrokeType?.kette,
                }),
              )
            }
          />
        </div>
        <div className="general-drives-data-dialog__active">
          <Switch
            labelText="Aktiv"
            turnedOn={editedStrokeType.active || false}
            onChange={() =>
              dispatch(
                updateEditedStrokeType({
                  ...editedStrokeType,
                  active: !editedStrokeType?.active,
                }),
              )
            }
          />
        </div>
        <div className="sub-header general-drives-data-dialog__diagram-table-sub-header">
          <div className="sub-header__title">Drucklastdiagramm</div>
          <button
            className="sub-header__button sub-header__button--add"
            onClick={e => {
              addPoint();
              e.preventDefault();
            }}
          >
            {UiConstants.NEW_ROW}
          </button>
        </div>
        <div className="general-drives-data-dialog__diagram-table">
          <Table>
            <TableHeader>
              <th>Hub (mm)</th>
              <th>Druckkraft (N)</th>
              <th></th>
            </TableHeader>
            <tbody>
              {editedStrokeType.drucklastPunkte?.map((p, i) => (
                <TableRow>
                  <td>
                    <InputFieldNumber
                      value={p.x}
                      onChange={v => updatePointX(v, i)}
                      placeholder=""
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={p.y}
                      onChange={v => updatePointY(v, i)}
                      placeholder=""
                    />
                  </td>
                  <td>
                    <Button
                      type={ButtonType.SECONDARY}
                      action={e => {
                        deletePoint(i);
                        e.preventDefault();
                      }}
                      labelText="Löschen"
                    />
                  </td>
                </TableRow>
              ))}
            </tbody>
          </Table>
        </div>
      </FormLayout>
    </Dialog>
  );
};
const GeneralDrivesDataDialogStrokeTypes: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const editedDrive = useEditedDrive();
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  function triggerCreationMode(): void {
    setDialogIsShown(true);
    dispatch(
      updateEditedStrokeType({
        drucklastPunkte: [],
        bezeichnung: null,
        kette: null,
        elementgroesse: null,
        active: false,
        index: undefined,
      }),
    );
  }

  function deleteStrokeType(strokeType: NewStrokeType): void {
    dispatch(
      updateEditedDrive({
        ...editedDrive,
        hubtypen: editedDrive?.hubtypen?.filter(h => h !== strokeType) || null,
      }),
    );
  }

  function triggerEditMode(s: NewStrokeType): void {
    setDialogIsShown(true);
    dispatch(
      updateEditedStrokeType({
        ...s,
        index: editedDrive?.hubtypen?.indexOf(s) || -1,
      }),
    );
  }

  return (
    <div className="general-drives-data-dialog__stroke-types">
      <StrokeTypeDialog
        dialogIsShown={dialogIsShown}
        setDialogIsShown={setDialogIsShown}
      />
      <div className="sub-header">
        <div className="sub-header__title">Hub-Typen</div>
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          Neuer Eintrag
        </button>
      </div>
      <div className="general-drives-data-dialog__stroke-table">
        <Table>
          <TableHeader>
            <th>Bezeichnung</th>
            <th>Elementgröße (mm)</th>
            <th>Kette</th>
            <th>Zahnstange</th>
            <th>Aktiviert</th>
            <th>Aktion</th>
          </TableHeader>
          <tbody>
            {editedDrive?.hubtypen?.map(h => (
              <TableRow>
                <td>{h.bezeichnung}</td>
                <td>{h.elementgroesse}</td>
                <td>
                  <AdminListIcon checked={h.kette} />
                </td>
                <td>
                  <AdminListIcon checked={!h.kette} />
                </td>
                <td>
                  <AdminListIcon checked={h.active} />
                </td>
                <td className="general-drives-data-dialog__stroke-table-button-column">
                  <button
                    onClick={e => {
                      triggerEditMode(h);
                      e.preventDefault();
                    }}
                  >
                    {UiConstants.EDIT}
                  </button>
                  <button
                    onClick={() =>
                      window.confirm(`Hubtyp ${h.bezeichnung} löschen?`) &&
                      deleteStrokeType(h)
                    }
                  >
                    {UiConstants.DELETE}
                  </button>
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const GeneralDrivesDataDialogDriveStrokeVariants: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const editedDrive = useEditedDrive();
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  function addRow(): void {
    const driveStrokeVariants = editedDrive!.antriebsHubVarianten || [];
    driveStrokeVariants.push({
      id: undefined,
      maxHub: undefined,
      gewicht: undefined,
      laenge: undefined,
      tiefe: undefined,
      breite: undefined,
    });

    editedDrive &&
      dispatch(
        updateEditedDrive({
          ...editedDrive,
          antriebsHubVarianten: driveStrokeVariants,
        }),
      );
  }

  function deleteRow(index: number): void {
    const driveStrokeVariants = editedDrive!.antriebsHubVarianten!;
    driveStrokeVariants.splice(index, 1);
    editedDrive &&
      dispatch(
        updateEditedDrive({
          ...editedDrive,
          antriebsHubVarianten: driveStrokeVariants,
        }),
      );
  }

  return (
    <div className="general-drives-data-dialog__layout-drive-stroke-variants">
      <div className="sub-header">
        <div className="sub-header__title">Antriebs Hub Varianten</div>
        <button
          className="sub-header__button sub-header__button--add"
          onClick={e => {
            addRow();
            e.preventDefault();
          }}
        >
          {UiConstants.NEW_ROW}
        </button>
      </div>

      <div className="general-drives-data-dialog__drive-stroke-table">
        <Table>
          <TableHeader>
            <th>Hub (mm)</th>
            <th>Breite (mm)</th>
            <th>Länge (mm)</th>
            <th>Tiefe (mm)</th>
            <th>Gewicht (kg)</th>
            {editedDrive.powerAndPowerConsumption ===
              PowerAndpowerConsumption.STROKE_VARIANTS && (
              <>
                <th>Stromaufnahme (A)</th>
                <th>Leistung (W)</th>
              </>
            )}
            <th>Aktion</th>
          </TableHeader>
          <tbody>
            {editedDrive?.antriebsHubVarianten?.map(
              (driveStrokeVariant, index) => (
                <TableRow>
                  <td>
                    <InputFieldNumber
                      value={driveStrokeVariant.maxHub}
                      onChange={newValue => {
                        const elementsToUpdate =
                          editedDrive?.antriebsHubVarianten;

                        elementsToUpdate &&
                          elementsToUpdate.splice(index, 1, {
                            ...editedDrive?.antriebsHubVarianten![index],
                            maxHub: newValue,
                          });
                        dispatch(
                          updateEditedDrive({
                            ...editedDrive!,
                            antriebsHubVarianten: elementsToUpdate,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={driveStrokeVariant.breite}
                      onChange={newValue => {
                        const elementsToUpdate =
                          editedDrive?.antriebsHubVarianten;
                        elementsToUpdate &&
                          elementsToUpdate.splice(index, 1, {
                            ...editedDrive?.antriebsHubVarianten![index],
                            breite: newValue,
                          });
                        dispatch(
                          updateEditedDrive({
                            ...editedDrive!,
                            antriebsHubVarianten: elementsToUpdate,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={driveStrokeVariant.laenge}
                      onChange={newValue => {
                        const elementsToUpdate =
                          editedDrive?.antriebsHubVarianten;
                        elementsToUpdate &&
                          elementsToUpdate.splice(index, 1, {
                            ...editedDrive?.antriebsHubVarianten![index],
                            laenge: newValue,
                          });
                        dispatch(
                          updateEditedDrive({
                            ...editedDrive!,
                            antriebsHubVarianten: elementsToUpdate,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={driveStrokeVariant.tiefe}
                      onChange={newValue => {
                        const elementsToUpdate =
                          editedDrive?.antriebsHubVarianten;
                        elementsToUpdate &&
                          elementsToUpdate.splice(index, 1, {
                            ...editedDrive?.antriebsHubVarianten![index],
                            tiefe: newValue,
                          });
                        dispatch(
                          updateEditedDrive({
                            ...editedDrive!,
                            antriebsHubVarianten: elementsToUpdate,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={driveStrokeVariant.gewicht}
                      onChange={newValue => {
                        const elementsToUpdate =
                          editedDrive?.antriebsHubVarianten;
                        elementsToUpdate &&
                          elementsToUpdate.splice(index, 1, {
                            ...editedDrive?.antriebsHubVarianten![index],
                            gewicht: newValue,
                          });
                        dispatch(
                          updateEditedDrive({
                            ...editedDrive!,
                            antriebsHubVarianten: elementsToUpdate,
                          }),
                        );
                      }}
                    />
                  </td>
                  {editedDrive.powerAndPowerConsumption ===
                    PowerAndpowerConsumption.STROKE_VARIANTS && (
                    <>
                      <td>
                        <InputFieldNumber
                          value={driveStrokeVariant.powerConsumption}
                          onChange={newValue => {
                            const elementsToUpdate =
                              editedDrive?.antriebsHubVarianten;
                            elementsToUpdate &&
                              elementsToUpdate.splice(index, 1, {
                                ...editedDrive?.antriebsHubVarianten![index],
                                powerConsumption: newValue,
                              });
                            dispatch(
                              updateEditedDrive({
                                ...editedDrive!,
                                antriebsHubVarianten: elementsToUpdate,
                              }),
                            );
                          }}
                        />
                      </td>
                      <td>
                        <InputFieldNumber
                          value={driveStrokeVariant.power}
                          onChange={newValue => {
                            const elementsToUpdate =
                              editedDrive?.antriebsHubVarianten;
                            elementsToUpdate &&
                              elementsToUpdate.splice(index, 1, {
                                ...editedDrive?.antriebsHubVarianten![index],
                                power: newValue,
                              });
                            dispatch(
                              updateEditedDrive({
                                ...editedDrive!,
                                antriebsHubVarianten: elementsToUpdate,
                              }),
                            );
                          }}
                        />
                      </td>
                    </>
                  )}
                  <td>
                    <Button
                      type={ButtonType.SECONDARY}
                      action={e => {
                        deleteRow(index);
                        e.preventDefault();
                      }}
                      labelText="Löschen"
                    />
                  </td>
                </TableRow>
              ),
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const DrivesData: FC<React.PropsWithChildren<unknown>> = () => {
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const drives = useSelector<AdminState, AdminDrive[]>(
    state => state.adminFacade.drives,
  );
  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [filterActive, setFilterActive, searchResult] = useAdminSearch(
    drives,
    searchString,
    ['artikelNr', 'bezeichnung', 'info'],
    (d: AdminDrive) => [d.antriebsfamilie.antriebsfamilie],
  );

  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  function enableEditFamily(family: AdminDrive): void {
    setDialogIsShown(true);
    dispatch(updateEditedDrive(family));
  }

  function triggerCreationMode(): void {
    setDialogIsShown(true);
    dispatch(updateEditedDrive({} as Edit<NewAdminDrive>));
  }

  function getCurrentTableContent(): AdminDrive[] {
    return searchResult.slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }

  return (
    <>
      <DrivesDialog
        setDialogIsShown={setDialogIsShown}
        dialogIsShown={dialogIsShown}
      />
      <div className="sub-header">
        <div className="sub-header__title">Antriebe</div>
        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText="Antriebe suchen..."
          small={true}
          setFilterActive={setFilterActive}
          filterActive={filterActive}
        />

        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          {UiConstants.NEW_ENTRY}
        </button>
      </div>
      <div className="cv-data">
        <Table>
          <TableHeader>
            <th>Artikelnummer</th>
            <th>Bezeichnung</th>
            <th>Info</th>
            <th>Antriebsfamilie</th>
            <th>Aktiv?</th>
            <th>Aktion</th>
          </TableHeader>
          <tbody>
            {getCurrentTableContent().map(d => (
              <TableRow key={d.id}>
                <td>{d.artikelNr}</td>
                <td>{d.bezeichnung}</td>
                <td>{d.info}</td>
                <td>{d.antriebsfamilie.antriebsfamilie}</td>

                <td>
                  <AdminListIcon checked={d.active} />
                </td>

                <td className="general-drives-data-dialog__stroke-table-button-column">
                  <button onClick={() => enableEditFamily(d)}>
                    {UiConstants.EDIT}
                  </button>
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        searchString={searchString}
        numberOfPages={searchResult.length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </>
  );
};
export default DrivesData;
