import React, { FC, ReactElement, ReactNode } from 'react';
import NumberField from './NumberField';
import { useDispatch, useSelector } from 'react-redux';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import { isDoubleFlapApplication, ValueKey } from '../../../redux/valueKey';
import { changeCalculationParameter } from '../../../redux/parametersActions';
import { FormattedMessage } from 'react-intl';
import { Locale } from '../../../redux/uiStateReducer';
import EditableBox from './EditableBox';
import { Range, Ranges, Validation } from '../../../redux/nrwgReducer';
import classNames from 'classnames';
import './WindowAreaBox.scss';
import FieldLabel from '../../../elements/FieldLabel';
import { formatNumber } from '../../../lib/utils';
import { AnyAction } from 'redux';
import { Help } from '../ParameterSection';

interface WindowAreaBoxProps {
  isSingleEdit: boolean;
  valueKey: ValueKey;
  validatedFields: ValueKey[] | undefined;
  helpContent: Help;
  dynamicHelpContent?: ReactNode;
}

interface RatioRangeProps {
  range: Range;
}

// const RATIOS: {
//   label: string;
//   property: RangeKeys;
// }[] = [
//   {
//     label: 'WINDOW_AREA_BOX_LABEL_CALCULATED_RATIO_SASH_WIDTH_TO_SASH_HEIGHT',
//     property: 'ratioSashWidthToSashHeight',
//   },
//   {
//     label:
//       'WINDOW_AREA_BOX_LABEL_CALCULATED_RATIO_DOUBLE_FLAP_WIDTH_TO_SASH_HEIGHT',
//     property: 'ratioDoubleFlapWidthToSashHeight',
//   },
//   {
//     label:
//       'WINDOW_AREA_BOX_LABEL_CALCULATED_RATIO_SASH_WIDTH_TO_DOUBLE_FLAP_HEIGHT',
//     property: 'ratioSashWidthToDoubleFlapHeight',
//   },
//   {
//     label: 'WINDOW_AREA_BOX_LABEL_CALCULATED_RATIO_SASH_HEIGHT_TO_SASH_WIDTH',
//     property: 'ratioSashHeightToSashWidth',
//   },
//   {
//     label:
//       'WINDOW_AREA_BOX_LABEL_CALCULATED_RATIO_DOUBLE_FLAP_HEIGHT_TO_SASH_WIDTH',
//     property: 'ratioDoubleFlapHeightToSashWidth',
//   },
//   {
//     label:
//       'WINDOW_AREA_BOX_LABEL_CALCULATED_RATIO_SASH_HEIGHT_TO_DOUBLE_FLAP_WIDTH',
//     property: 'ratioSashHeightToDoubleFlapWidth',
//   },
//   {
//     label: 'WINDOW_AREA_BOX_LABEL_CALCULATED_RATIO_CLEAR_WIDTH_TO_CLEAR_HEIGHT',
//     property: 'ratioClearWidthToClearHeight',
//   },
//   {
//     label:
//       'WINDOW_AREA_BOX_LABEL_CALCULATED_RATIO_CLEAR_DOUBLE_FLAP_WIDTH_TO_CLEAR_HEIGHT',
//     property: 'ratioClearDoubleFlapWidthToClearHeight',
//   },
//   {
//     label:
//       'WINDOW_AREA_BOX_LABEL_CALCULATED_RATIO_CLEAR_WIDTH_TO_CLEAR_DOUBLE_FLAP_HEIGHT',
//     property: 'ratioClearWidthToClearDoubleFlapHeight',
//   },
//   {
//     label: 'WINDOW_AREA_BOX_LABEL_CALCULATED_RATIO_CLEAR_HEIGHT_TO_CLEAR_WIDTH',
//     property: 'ratioClearHeightToClearWidth',
//   },
//   {
//     label:
//       'WINDOW_AREA_BOX_LABEL_CALCULATED_RATIO_CLEAR_DOUBLE_FLAP_HEIGHT_TO_CLEAR_WIDTH',
//     property: 'ratioClearDoubleFlapHeightToClearWidth',
//   },
//   {
//     label:
//       'WINDOW_AREA_BOX_LABEL_CALCULATED_RATIO_HEIGHT_TO_CLEAR_CLEAR_DOUBLE_FLAP_WIDTH',
//     property: 'ratioClearHeightToClearDoubleFlapWidth',
//   },
// ];

const RatioRange: FC<React.PropsWithChildren<RatioRangeProps>> = props => {
  const locale = useSelector<IState, Locale>(s => s.authentication.locale);

  function isDefined(value: string | number): boolean {
    return !(value === 'Infinity');
  }

  if (isDefined(props.range.min) && isDefined(props.range.max)) {
    return (
      <>
        {formatNumber(props.range.min, locale)} -{' '}
        {formatNumber(props.range.max, locale)}
      </>
    );
  }

  if (isDefined(props.range.min) && !isDefined(props.range.max)) {
    return (
      <>
        <FormattedMessage id="AT_LEAST" />{' '}
        {formatNumber(props.range.min, locale)}
      </>
    );
  }

  return (
    <>
      <FormattedMessage id="AT_MOST" /> {formatNumber(props.range.max, locale)}
    </>
  );
};

interface RatioProps {
  label: string;
  valid: boolean;
  range: Range | undefined;
}

export const Ratio: FC<React.PropsWithChildren<RatioProps>> = props => {
  const locale = useSelector<IState, Locale>(s => s.authentication.locale);

  if (!props.range) {
    return null;
  }

  return (
    <React.Fragment key={props.label + props.range.calculatedValue}>
      <div className="window-area-box__calculated-ratio">
        <FormattedMessage id={props.label} />:{' '}
        {formatNumber(props.range.calculatedValue, locale)}
      </div>
      <div
        className={classNames('window-area-box__tested-ratio', {
          'window-area-box__tested-ratio--invalid': !props.valid,
        })}
      >
        <div className="window-area-box__tested-ratio-label">
          <FormattedMessage id="WINDOW_AREA_BOX_LABEL_TESTED_AREA" />:{' '}
          <RatioRange range={props.range} />
        </div>
      </div>
    </React.Fragment>
  );
};

function DoubleFlapHint(props: { message: string }): ReactElement {
  return (
    <div className="window-area-box__double-flap-hint">
      <div className="window-area-box__double-flap-hint-icon">
        error_outlined
      </div>
      <div className="window-area-box__double-flap-hint-message">
        <FormattedMessage id={props.message} />
      </div>
    </div>
  );
}

const WindowAreaBox: FC<
  React.PropsWithChildren<WindowAreaBoxProps>
> = props => {
  const width = useSelector<IState, number | undefined>(s =>
    s.parameters.sashWidth.toNumber(),
  );
  const height = useSelector<IState, number | undefined>(s =>
    s.parameters.sashHeight.toNumber(),
  );
  const windowArea = useSelector<IState, number | undefined>(s =>
    s.parameters.area.toNumber(),
  );
  const sashFrameDistance = useSelector<IState, number | undefined>(s =>
    s.parameters.sashFrameDistance.toNumber(),
  );
  const ranges = useSelector<IState, Ranges | undefined>(s => s.nrwg.ranges);
  const validation = useSelector<IState, Validation>(s => s.nrwg.validation!);
  const doubleFlapApplication = useSelector<IState, boolean>(
    isDoubleFlapApplication,
  );
  const locale = useSelector<IState, Locale>(s => s.authentication.locale);
  const focusedField = useSelector<IState, ValueKey>(s => s.ui.focusedField);
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();

  function areaInvalid(): boolean {
    if (!validation) {
      return false;
    }

    return !validation.area;
  }

  return (
    <EditableBox {...props} heading="WINDOW_AREA_BOX_LABEL_HEADING">
      <div className="window-area-box">
        <div className="window-area-box__width">
          {' '}
          <FieldLabel>
            <FormattedMessage id="WINDOW_AREA_BOX_LABEL_WIDTH" />
            {doubleFlapApplication && (
              <DoubleFlapHint message="WINDOW_AREA_BOX_LABEL_WIDTH_DOUBLE_FLAP_HINT" />
            )}
          </FieldLabel>
          <NumberField
            range={[]}
            name="sashWidth"
            value={width}
            onChange={v =>
              dispatch(
                changeCalculationParameter(ValueKey.VALUEKEY_SASH_WIDTH, v),
              )
            }
            valueKey={ValueKey.VALUEKEY_SASH_WIDTH}
          />
          <div
            className={classNames('editable-box__range', {
              'editable-box__range editable-box__range--active':
                focusedField === ValueKey.VALUEKEY_SASH_WIDTH &&
                ranges?.sashWidth,
            })}
          >
            <FormattedMessage
              id="parameter_range_description_with_values"
              values={{
                min: ranges?.sashWidth.min,
                max: ranges?.sashWidth.max,
                unit: ' mm',
              }}
            />
          </div>
        </div>
        <div className="window-area-box__height">
          <FieldLabel>
            <FormattedMessage id="WINDOW_AREA_BOX_LABEL_HEIGHT" />
            {doubleFlapApplication && (
              <DoubleFlapHint message="WINDOW_AREA_BOX_LABEL_HEIGHT_DOUBLE_FLAP_HINT" />
            )}
          </FieldLabel>

          <NumberField
            range={[]}
            name="sasHeight"
            value={height}
            onChange={v =>
              dispatch(
                changeCalculationParameter(ValueKey.VALUEKEY_SASH_HEIGHT, v),
              )
            }
            valueKey={ValueKey.VALUEKEY_SASH_HEIGHT}
          />
          <div
            className={classNames('editable-box__range', {
              'editable-box__range editable-box__range--active':
                focusedField === ValueKey.VALUEKEY_SASH_HEIGHT &&
                ranges?.sashHeight,
            })}
          >
            <FormattedMessage
              id="parameter_range_description_with_values"
              values={{
                min: ranges?.sashHeight.min,
                max: ranges?.sashHeight.max,
                unit: ' mm',
              }}
            />
          </div>
        </div>

        {doubleFlapApplication && (
          <div className="window-area-box__sash-frame-distance">
            {' '}
            <FieldLabel>
              <FormattedMessage id="WINDOW_AREA_BOX_LABEL_SASH_FRAME_DISTANCE" />{' '}
              (mm)
            </FieldLabel>
            <NumberField
              range={[]}
              name="sashFrameDistance"
              value={sashFrameDistance}
              onChange={v =>
                dispatch(
                  changeCalculationParameter(ValueKey.SASH_FRAME_DISTANCE, v),
                )
              }
              valueKey={ValueKey.SASH_FRAME_DISTANCE}
            />
            <div
              className={classNames('editable-box__range', {
                'editable-box__range editable-box__range--active':
                  focusedField === ValueKey.SASH_FRAME_DISTANCE,
              })}
            >
              <FormattedMessage
                id="parameter_range_description_with_values"
                values={{
                  min: ranges?.sashFrameDistance?.min,
                  max: ranges?.sashFrameDistance?.max,
                  unit: ' mm',
                }}
              />
            </div>
          </div>
        )}

        {ranges?.doubleFlapHeight?.calculatedValue && (
          <div className="window-area-box__calculated-double-flap-width">
            <FormattedMessage id="WINDOW_AREA_BOX_CALCULATE_DOUBLE_FLAP_HEIGHT" />
            : {ranges.doubleFlapHeight.calculatedValue} mm
          </div>
        )}

        {ranges?.doubleFlapWidth?.calculatedValue && (
          <div className="window-area-box__calculated-double-flap-width">
            <FormattedMessage id="WINDOW_AREA_BOX_CALCULATE_DOUBLE_FLAP_WIDTH" />
            : {ranges.doubleFlapWidth.calculatedValue} mm
          </div>
        )}

        <div className="window-area-box__calculated-area">
          <FormattedMessage
            id={
              doubleFlapApplication
                ? 'WINDOW_AREA_BOX_LABEL_CALCULATED_AREA_DOUBLE_FLAP'
                : 'WINDOW_AREA_BOX_LABEL_CALCULATED_AREA'
            }
          />
          :{' '}
          {windowArea && (
            <>
              {ranges?.area.calculatedValue
                ? `${formatNumber(ranges?.area.calculatedValue, locale)} m²`
                : ''}
            </>
          )}
        </div>
        {ranges && (
          <>
            <div
              className={classNames('window-area-box__tested-area', {
                'window-area-box__tested-area--invalid': areaInvalid(),
              })}
            >
              <div className="window-area-box__tested-area-label">
                <FormattedMessage id="WINDOW_AREA_BOX_LABEL_TESTED_AREA" />:{' '}
                {formatNumber(ranges.area.min, locale)} -{' '}
                {formatNumber(ranges.area.max, locale)} m²
              </div>
            </div>

            {Object.entries(ranges)
              .filter(v => v[1] && v[1].label)
              .map(([property, ratio]) => (
                <Ratio
                  label={ratio.label}
                  valid={!validation || validation[property]}
                  range={ratio}
                  key={ratio.label}
                />
              ))}
          </>
        )}
      </div>
    </EditableBox>
  );
};

export default WindowAreaBox;
