import React, { FC, ReactNode, useEffect } from 'react';
import './EditableBox.scss';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  showDialog,
  updateOverlayActive,
  updateSingleEdit,
} from '../../../redux/uiStateActions';
import InformationDialog from '../../InformationDialog';
import { ValueKey } from '../../../redux/valueKey';
import { Validation } from '../../../redux/nrwgReducer';
import _ from 'lodash';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import classNames from 'classnames';
import { AnyAction } from 'redux';
import { Help } from '../ParameterSection';

interface DialogContent {
  helpContent?: Help;
  dynamicHelpContent?: ReactNode;
}
export interface EditableBoxProps extends DialogContent {
  heading: string;
  range?: number[];
  unit?: string;
  isSingleEdit: boolean;
  elementToFocusSelector?: string;
  valueKey: ValueKey;
  usedOutsideOfParameterSection?: boolean;
  validatedFields?: ValueKey[];
}

interface PrivateEditableBoxProps extends EditableBoxProps {
  children: React.ReactNode;
}

export const EditableBox: FC<
  React.PropsWithChildren<PrivateEditableBoxProps>
> = props => {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const validation = useSelector<IState, Validation | undefined>(
    s => s.nrwg.validation,
  );

  useEffect(() => {
    if (props.isSingleEdit) {
      const inputElement = document.querySelector(
        props.elementToFocusSelector || '.editable-box input',
      ) as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [props.isSingleEdit, props.elementToFocusSelector]);

  function invalidModifiers(): string[] {
    function invalidModifier(valueKey: ValueKey): string {
      return `editable-box--${_.kebabCase(valueKey.valueOf())}-invalid`;
    }

    if (validation) {
      if (props.validatedFields) {
        return [
          ...props.validatedFields
            .map(f => {
              if (validation[f.valueOf()] === false) {
                return invalidModifier(f);
              }
              return null;
            })
            .filter(v => v),
          'editable-box--invalid',
        ] as string[];
      } else {
        return !validation[props.valueKey.valueOf()]
          ? [invalidModifier(props.valueKey), 'editable-box--invalid']
          : [];
      }
    }

    return [];
  }

  return (
    <div
      className={classNames('editable-box', ...invalidModifiers())}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          dispatch(updateSingleEdit(false, undefined));
        }
      }}
    >
      <div className="editable-box__content">
        {!props.usedOutsideOfParameterSection && (
          <h3 className="editable-box__title">
            <FormattedMessage id={props.heading} />{' '}
            {props.isSingleEdit ? (
              <div className="editable-box__close-button-hover-area">
                {/* button_close necessary to find the size of the button in css*/}
                <FormattedMessage id="button_close" />
                <button
                  className="editable-box__close-button"
                  onClick={() => {
                    dispatch(updateSingleEdit(false, undefined));
                  }}
                  type="button"
                >
                  <div className="editable-box__close-button-title">
                    <FormattedMessage id="button_close" />
                  </div>
                </button>
              </div>
            ) : null}
          </h3>
        )}{' '}
        {props.children}
        {!props.usedOutsideOfParameterSection && (
          <div
            className="editable-box__information-icon-container"
            onClick={() => {
              dispatch(
                showDialog(
                  <InformationDialog
                    key={props.heading}
                    heading={props.heading}
                    help={props.helpContent}
                    dynamicHelpContent={props.dynamicHelpContent}
                    dialogIsForValueKey={props.valueKey}
                  />,
                ),
              );
              dispatch(updateOverlayActive(true));
            }}
          />
        )}
      </div>
    </div>
  );
};

export default EditableBox;
