import React, { FC, useCallback } from 'react';
import './TabBar.scss';
import { MDCTabBar } from '@material/tab-bar';
import '@material/tab-bar/mdc-tab-bar.scss';
import '@material/tab-scroller/mdc-tab-scroller.scss';
import '@material/tab-indicator/mdc-tab-indicator.scss';
import '@material/tab/mdc-tab.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  IState,
  MyCalcDispatch,
  MyCalcThunkDispatch,
} from '../../../redux/store';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { SaveComponentLeadingValueKeyAction } from '../ParameterSection';
import { ValueKey } from '../../../redux/valueKey';
import { AnyAction } from 'redux';

interface TabBarBoxProps {
  valueKey: ValueKey;
  options: Option[];
  saveComponentLeadingValueKeyAction: SaveComponentLeadingValueKeyAction;
  name: string;
  hint?: (state: IState) => string;
  selectedValueKey: string;
}

interface Option {
  key: string;
  name: string;
  callback?: (dispatch: MyCalcDispatch) => void;
}

interface ButtonProps {
  valueKey: string;
  active: boolean;
  label: string;
  saveComponentLeadingValueKeyAction: SaveComponentLeadingValueKeyAction;
  callback?: (dispatch: MyCalcDispatch) => void;
}

const Button: FC<React.PropsWithChildren<ButtonProps>> = (
  props: ButtonProps,
) => {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  return (
    <button
      onClick={() => {
        dispatch(props.saveComponentLeadingValueKeyAction(props.valueKey));
        props.callback && props.callback(dispatch);
      }}
      className={classNames('mdc-tab tab-bar__tab', {
        'mdc-tab--active': props.active,
      })}
      role="tab"
    >
      <span className="mdc-tab__content">
        <span className="tab-bar__label">
          <FormattedMessage id={props.label} />
        </span>
      </span>
      <span
        className={classNames('mdc-tab-indicator ', {
          'mdc-tab-indicator--active tab-bar__indicator tab-bar__indicator--active':
            props.active,
          'tab-bar--indicator': !props.active,
        })}
      >
        <span className="mdc-tab-indicator__content mdc-tab-indicator__content--underline" />
      </span>
      <span className="mdc-tab__ripple" />
    </button>
  );
};

const TabBar: FC<React.PropsWithChildren<TabBarBoxProps>> = (
  props: TabBarBoxProps,
) => {
  const state = useSelector((state: IState) => state);

  const tabBarRef = useCallback((tab: any) => {
    if (tab !== null) {
      new MDCTabBar(tab);
    }
  }, []);

  return (
    <>
      <div className="mdc-tab-bar tab-bar" role="tablist" ref={tabBarRef}>
        <div className="mdc-tab-scroller tab-bar__scroller">
          <div className="mdc-tab-scroller__scroll-area tab-bar__scroll-area">
            <div className="mdc-tab-scroller__scroll-content tab-bar__scroll-content">
              {props.options.map(option => (
                <Button
                  key={option.name}
                  valueKey={option.key || ValueKey.VALUEKEY_NONE}
                  active={
                    option.key ===
                    state.parameters[props.selectedValueKey].value
                  }
                  label={option.name}
                  saveComponentLeadingValueKeyAction={
                    props.saveComponentLeadingValueKeyAction
                  }
                  callback={option.callback}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabBar;
