import { AnyAction, Reducer } from 'redux';

import _ from 'lodash';
import { DriveType } from './constants';
import { ConsoleSet } from './staticDataReducer';

interface GeneralError {
  message: string;
  solutions: string[];
  values?: Record<string, string>;
}

export interface Hint {
  hideOnCalculationPage: boolean;
  general: boolean;
  accepted: boolean;
  acceptable: boolean;
  parameters: Record<string, any>;
  type: string;
}

export interface CalculationResult {
  suitableDrives: Drive[];
  partlyQualifiedDrives: Drive[];
  generalError?: GeneralError;
  generalHints: Hint[];
}

interface DriveCalculationResult {
  maximumRequiredPushPower: number;
  maximumRequiredPushPowerAtStroke: number;
  sashWeight: number;
  openingStroke: number;
  maximumRequiredPullPower: number;
  maximumRequiredPullPowerAtStroke: number;
  requiredLockingPressure: number;
  requiredForceAtZeroStroke: number;
  calculatedForces: Record<string, number>;
  geometricArea: number;
  maxGeometricArea: number;
}

export interface LockingDriveInformation {
  articleNumber: string;
  articleDrive: string;
  articleConsole: string;
  lockingDriveType: string;
  lockingConsoleArticleNumber: string;
}

export interface Drive {
  roofAndHasNrwgCertificate: boolean;
  facadeAndHasNrwgCertificate: boolean;
  hints: Hint[];
  active: boolean;
  installationDiagramName: string;
  name: string;
  itemNumber: string;
  maximumStroke: number;
  maximumPushPower: number;
  maximumPullPower: number;
  lockingForce: number;
  performance: Performance | undefined;
  descriptionKey: string;
  type: DriveType;
  errors: string[];
  isCustomMade: boolean;
  isVentilation: boolean;
  voltage: string;
  driveId: string;
  numberOfDrives: number;
  realOpeningAngle: number | undefined;
  driveCalculationResult: DriveCalculationResult;
  lockingDriveInformation: LockingDriveInformation | undefined;
  glassThickness: number;
  sashWeight: number;
  includedConsoleSet: ConsoleSet | undefined;
}

export interface Performance {
  lockingForceHint: string | null;
  pushForce: number;
  pushForceRequired: number;
  pullForce: number;
  pullForceRequired: number;
  stroke: number;
  strokeRequired: number;
  holdingForce: number;
  holdingForceRequired: number;
  lockingForce: number | null;
  lockingForceRequired: number | null;
  strokeForceData: Record<string, number>;
}

export const INITIAL_STATE = {
  suitableDrives: [],
  partlyQualifiedDrives: [],
  nonSuitableDrives: [],
  errors: [],
};

export function sortByErrorCount(drives: Drive[]): Drive[] {
  return _.sortBy(drives, d => d.errors.length);
}

const calculationResultReducer: Reducer = (
  state: CalculationResult,
  action: AnyAction,
) => {
  if (!state) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case 'UPDATE_CALCULATION_RESULT':
      if (!action.calculationResult) {
        return state;
      }

      return {
        ...INITIAL_STATE,
        ...action.calculationResult,
        partlyQualifiedDrives: sortByErrorCount(
          action.calculationResult.partlyQualifiedDrives,
        ),
      };

    case 'RESET_CALCULATION_RESULT':
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default calculationResultReducer;
