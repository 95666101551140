import { Reducer } from 'redux';
import { PROJECT_ACTIONS } from './projectsActions';
import { ParametersState } from './parametersReducer';
import { ConsoleSet } from './staticDataReducer';
import { WindDeflector } from './nrwgReducer';
import {
  AerodynamicDesmoking,
  GeometricDesmoking,
} from './desmokingResultReducer';
import { Drive, Hint } from './calculationResultReducer';
import { LoggedInUser } from './authenticationReducer';
import { RangeOfApplication } from './constants';

export interface Window {
  acceptedHints: Hint[];
  updatedAt?: string | unknown;
  createdAt?: string | unknown;
  id?: number;
  name: string;
  quantity: number;
  calculationParameters: ParametersState;
  nrwg: boolean;
  rwa: boolean;
  locked: boolean;
  consoleSetItemNumber?: string;
  consoleSets?: ConsoleSet[];
  windDeflector?: WindDeflector;
  aerodynamicDesmokingResult?: AerodynamicDesmoking;
  geometricDesmokingResult?: GeometricDesmoking;
  uniqueNumber: string;
  selectedDrive?: Drive;
  nshevCanBeCompleted: boolean;
}

export interface ShallowWindow {
  description: string;
  numberOfDrives: number;
  rangeOfApplication: RangeOfApplication;
  id: number;
  name: string;
  quantity: number;
  nrwg: boolean;
  rwa: boolean;
  locked: boolean;
  uniqueNumber: string;
  consoleSetNames: string[] | undefined;
  driveName: string | undefined;
  sashWidth: number | undefined;
  sashHeight: number | undefined;
}

export type BuildingArea = {
  name: string;
  id?: number;
  windows: ShallowWindow[];
};

export const NEW_BUILDING_AREA: BuildingArea = {
  name: '',
  windows: [],
};

export const NEW_WINDOW: Partial<ShallowWindow> = {
  name: '',
  quantity: 1,
  nrwg: false,
  rwa: false,
  locked: false,
  numberOfDrives: 1,
  rangeOfApplication: RangeOfApplication.FACADE,
};

export interface Project extends NewProject {
  companyName: string;
  user: LoggedInUser;
  id: number;
  containsLockedWindows: boolean;
}

export interface ShallowProject {
  country: string;
  description: string;
  uniqueNumber: string;
  companyName: string;
  id: number;
  name: string;
  userName: string;
  containsLockedWindows: boolean;
}

export interface NewProject {
  name: string;
  description: string;
  country: string;
  id: number | undefined;
  buildingAreas: BuildingArea[];
  orderNumber?: string;
  uniqueNumber: string;
}

export type ProjectsState = {
  currentProjectsPage: number;
  searchString: string;
  projects: ShallowProject[];
  totalPages: number;
};

export const INITIAL_STATE: ProjectsState = {
  searchString: '',
  projects: [],
  totalPages: 0,
  currentProjectsPage: 0,
};

const projectsReducer: Reducer<ProjectsState | undefined, PROJECT_ACTIONS> = (
  state: ProjectsState | undefined,
  action: PROJECT_ACTIONS,
) => {
  if (state === undefined) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case 'UPDATE_PROJECTS': {
      return {
        ...state,
        projects: action.projects,
        totalPages: action.totalPages,
        currentProjectsPage: action.page,
      };
    }
    case 'UPDATE_PROJECT_SEARCH_STRING': {
      return { ...state, searchString: action.searchString };
    }

    default:
      return state;
  }
};

export default projectsReducer;
