import React, { FC, Fragment } from 'react';
import EditableBox from './EditableBox';
import './TabBarBoxWithOutValues.scss';

import '@material/tab-bar/mdc-tab-bar.scss';
import '@material/tab-scroller/mdc-tab-scroller.scss';
import '@material/tab-indicator/mdc-tab-indicator.scss';
import '@material/tab/mdc-tab.scss';
import { useSelector } from 'react-redux';
import { IState } from '../../../redux/store';
import { FormattedMessage } from 'react-intl';
import { Options } from '../ParametersColumn';
import TabBar from './TabBar';
import { Help, SaveComponentLeadingValueKeyAction } from '../ParameterSection';
import { ValueKey } from '../../../redux/valueKey';

interface TabBarWithoutValuesProps {
  valueKey: ValueKey;
  options: Options;
  saveComponentLeadingValueKeyAction: SaveComponentLeadingValueKeyAction;
  name: string;
  hint?: (state: IState) => string;
  selectedValueKey: string;
}

interface TabBarWithoutValuesBoxProps extends TabBarWithoutValuesProps {
  name: string;
  isSingleEdit: boolean;
  helpContent?: Help;
}

const TabBarWithOutValues: FC<
  React.PropsWithChildren<TabBarWithoutValuesProps>
> = (props: TabBarWithoutValuesProps) => {
  const state = useSelector((state: IState) => state);

  return (
    <Fragment key={props.valueKey}>
      <TabBar
        {...props}
        options={props.options.map(o => {
          return { name: o.name, key: o.value || '', callback: o.callback };
        })}
      />
      {props.hint && (
        <div className="tab-bar-without-values__annotation">
          <FormattedMessage id={props.hint(state).toString()} />
        </div>
      )}
    </Fragment>
  );
};

export const TabBarBoxWithOutValues: FC<
  React.PropsWithChildren<TabBarWithoutValuesBoxProps>
> = (props: TabBarWithoutValuesBoxProps) => {
  return (
    <EditableBox {...props} heading={props.name}>
      <TabBarWithOutValues
        valueKey={props.valueKey}
        options={props.options}
        name={props.name}
        saveComponentLeadingValueKeyAction={
          props.saveComponentLeadingValueKeyAction
        }
        hint={props.hint}
        selectedValueKey={props.selectedValueKey}
      />
    </EditableBox>
  );
};

export default TabBarBoxWithOutValues;
