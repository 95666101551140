import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import './SearchField.scss';
import CheckBox from './CheckBox';

interface SearchFieldProps {
  setSearchString: (searchstring: string) => void;
  searchString: string;
  profileCombination?: boolean;
  placeholder?: string;
  placeholderText?: string;
  small?: boolean;
  filterActive?: boolean;
  setFilterActive?: Dispatch<SetStateAction<boolean>>;
  /** If true, set the cursor focus on the search input on initialization */
  captureFocus?: boolean;
}

export const SearchField: FC<React.PropsWithChildren<SearchFieldProps>> = (
  props: SearchFieldProps,
) => {
  const { formatMessage } = useIntl();
  const inputRef = useRef<HTMLInputElement | undefined>();

  const inputUpdateCallback = useCallback((input: any) => {
    if (input && inputRef.current !== input) {
      inputRef.current = input;
      input.focus();
    }
  }, []);

  const autoFocusProps = React.useMemo(
    () => (props.captureFocus ? { 'data-mdc-dialog-initial-focus': true } : {}),
    [props.captureFocus],
  );

  return (
    <div
      className={classNames('search-field', {
        'search-field--small': props.small,
      })}
    >
      <input
        ref={inputUpdateCallback}
        className="search-field__search-bar"
        type="text"
        autoComplete="off"
        placeholder={
          (props.placeholder && formatMessage({ id: props.placeholder })) ||
          props.placeholderText
        }
        id="searchField"
        onChange={event => {
          props.setSearchString(event.target.value);
        }}
        value={props.searchString}
        {...autoFocusProps}
      />
      {props.setFilterActive && (
        <CheckBox
          labelText="Aktiv"
          checked={props.filterActive}
          onClick={() => props.setFilterActive!(!props.filterActive)}
        />
      )}
    </div>
  );
};

export default SearchField;
