import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { IState } from '../../../redux/store';

import { FormattedMessage } from 'react-intl';
import './RecommendationsColumn.scss';
import {
  CalculationResult,
  Drive,
} from '../../../redux/calculationResultReducer';

import InfoMessage from '../../../elements/InfoMessage';
import { Calculating } from '../../../redux/uiStateReducer';
import DriveItem from './DriveItem';
import { RequestTypes } from '../../../redux/httpClient';
import { Column } from '../Column';
import TabNavigation, {
  TabNavItemProps,
} from '../../../elements/TabNavigation';
import { ReactComponent as Alert } from './alert.svg';
import {
  useSelectedDrive,
  useSelectedWindow,
} from '../../../hooks/selectorHooks';

export type ForceInformation = {
  pushForceExists: boolean;
  pullForceExists: boolean;
  pushAndPullExist: boolean;
};

const FIRST_INDEX_OF_SHOWN_DRIVES_SUITABLE = 1;

const RecommendationsColumn: FC<React.PropsWithChildren<unknown>> = () => {
  const selectedWindow = useSelectedWindow();
  const selectedDrive = useSelectedDrive();
  const invalidParameters = useSelector(
    (state: IState) =>
      !(!state.nrwg.validation || state.nrwg.validation?.valid),
  );
  const calculationResult = useSelector(
    (state: IState) => state.calculationResult,
  );

  const lastTabIndex = calculationResult.suitableDrives.length > 0 ? 1 : 0;
  const activeTab = calculationResult.partlyQualifiedDrives.find(
    d =>
      d.name === selectedDrive?.name &&
      d.numberOfDrives === selectedDrive?.numberOfDrives,
  )
    ? lastTabIndex
    : 0;

  const driveCalculationResult = selectedDrive?.driveCalculationResult;

  const calculating = useSelector<IState, Calculating>(s => s.ui.calculating);

  function loadingDrives(): boolean {
    return calculating[RequestTypes.CALCULATION] && !invalidParameters;
  }

  const forces: ForceInformation = {
    pushForceExists: pushForceExists(),
    pullForceExists: pullForceExists(),
    pushAndPullExist: pushAndPullForceExists(),
  };

  function pullForceExists(): boolean {
    return driveCalculationResult?.maximumRequiredPullPower !== 0;
  }

  function pushForceExists(): boolean {
    return driveCalculationResult?.maximumRequiredPushPower !== 0;
  }

  function pushAndPullForceExists(): boolean {
    return pullForceExists() && pushForceExists();
  }

  function heading(): string | undefined {
    if (invalidParameters) {
      return undefined;
    }
    if (calculationResult.suitableDrives.length !== 0) {
      return 'recommendation';
    }
    if (
      calculationResult.partlyQualifiedDrives.length !== 0 &&
      calculationResult.suitableDrives.length === 0
    ) {
      return 'partly_qualified_drives';
    }

    return undefined;
  }

  function getTabNavItems(
    calculationResult: CalculationResult,
  ): TabNavItemProps[] {
    const possibleTabNavItems = [
      {
        label: 'further_drives',
        body: (
          <Recommendations
            drives={calculationResult.suitableDrives.slice(
              FIRST_INDEX_OF_SHOWN_DRIVES_SUITABLE,
            )}
            forces={forces}
          />
        ),
      },
      {
        label: 'partly_qualified_drives',
        body: (
          <Recommendations
            drives={calculationResult.partlyQualifiedDrives}
            forces={forces}
          />
        ),
      },
    ];

    if (
      calculationResult.suitableDrives.length === 1 &&
      calculationResult.partlyQualifiedDrives.length >= 1
    ) {
      return [possibleTabNavItems[1]];
    }

    if (
      calculationResult.suitableDrives.length > 1 &&
      calculationResult.partlyQualifiedDrives.length === 0
    ) {
      return [possibleTabNavItems[0]];
    }

    if (
      calculationResult.suitableDrives.length === 0 &&
      calculationResult.partlyQualifiedDrives.length >= 1
    ) {
      return [possibleTabNavItems[1]];
    }

    return possibleTabNavItems;
  }

  function onlyPartlyQualifiedDrivesAsFurtherRecommendations(): boolean {
    return (
      calculationResult.suitableDrives.length === 1 &&
      calculationResult.partlyQualifiedDrives.length >= 1
    );
  }

  return !loadingDrives() ? (
    <Column
      heading={heading()}
      icon={
        calculationResult.suitableDrives.length === 0 ? <Alert /> : undefined
      }
      loading={false}
      className="recommendations-column"
    >
      {!invalidParameters && (
        <>
          {calculationResult.suitableDrives.length > 0 && (
            <DriveItem
              selected={
                selectedDrive?.name ===
                  calculationResult.suitableDrives[0].name &&
                selectedDrive?.numberOfDrives ===
                  calculationResult.suitableDrives[0].numberOfDrives
              }
              drive={calculationResult.suitableDrives[0]}
              forces={forces}
              qaId="recommended-drive"
            />
          )}

          {(calculationResult.suitableDrives.length > 0 ||
            calculationResult.partlyQualifiedDrives.length > 0) && (
            <TabNavigation
              tabNavItems={getTabNavItems(calculationResult)}
              withSingleTabNavItem={onlyPartlyQualifiedDrivesAsFurtherRecommendations()}
              activeTab={activeTab}
            />
          )}
        </>
      )}
      {((calculationResult.suitableDrives.length === 0 &&
        calculationResult.partlyQualifiedDrives.length === 0 &&
        !calculationResult?.generalError) ||
        invalidParameters) && (
        <div className="recommendations-column__empty-state">
          <InfoMessage>
            <FormattedMessage id="NO_RESULT" />
          </InfoMessage>
        </div>
      )}
      {!selectedWindow?.nrwg && invalidParameters && (
        <div className="performance-class-column__empty-state-invalid-parameters">
          <div className="performance-class-column__empty-state-invalid-parameters-marker"></div>
          <FormattedMessage id="INVALID_INPUT_FOR_LOCKING_DRIVE" />
        </div>
      )}

      {!invalidParameters && calculationResult?.generalError && (
        <>
          <InfoMessage>
            <FormattedMessage
              id={calculationResult.generalError.message}
              values={calculationResult.generalError.values}
            />
          </InfoMessage>

          <ul className={'recommendations-column__error-solution-list'}>
            <div className={'recommendations-column__solution-list-heading'}>
              {
                <FormattedMessage id="ERROR_MESSAGE_HOLDING_FORCE_POSSIBLE_SOLUTIONS" />
              }
            </div>

            {calculationResult.generalError.solutions.map(solution => (
              <li
                className={'recommendations-column__error-solution'}
                key={solution}
              >
                <FormattedMessage id={solution} />
              </li>
            ))}
          </ul>
        </>
      )}
    </Column>
  ) : (
    <Column
      loading={true}
      heading={heading()}
      className="recommendations-column"
    >
      <DriveItem loading={true} />
      <DriveItem loading={true} />
      <DriveItem loading={true} />
      <DriveItem loading={true} />
    </Column>
  );
};

interface RecommendationsProps {
  drives: Drive[];
  forces: ForceInformation;
}

const Recommendations: FC<React.PropsWithChildren<RecommendationsProps>> = (
  props: RecommendationsProps,
) => {
  const selectedDrive = useSelectedDrive();

  return (
    <div>
      {props.drives.map(drive => (
        <DriveItem
          drive={drive}
          key={drive.name + drive.numberOfDrives + drive.driveId}
          forces={props.forces}
          selected={
            selectedDrive?.name === drive.name &&
            selectedDrive?.numberOfDrives === drive.numberOfDrives
          }
        />
      ))}
    </div>
  );
};

export default RecommendationsColumn;
